<template>
  <div>
    <splitpanes class="default-theme">
      <pane
        size="25"
        class="pan-container"
      >
        <b-card class="h-100">
          <div class="node-tree-conent d-flex flex-column h-100">
            <div class="flex-grow-1">
              <!-- <node-tree /> -->
              <LeftTree />
            </div>
          </div>
        </b-card>
      </pane>
      <pane
        size="75"
        class="pan-container"
      >
        <div
          class="h-100"
          @click="clearTreeDocSelection"
        >
          <DataViewer />
        </div>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import DataViewer from '@/components/Classification/DataViewer.vue'
import LeftTree from '@/components/Classification/LeftTree.vue'
import {
  BCard,
} from 'bootstrap-vue'
import 'splitpanes/dist/splitpanes.css'
import bus from '@/bus'

export default {
  components: {
    Splitpanes,
    Pane,
    BCard,
    DataViewer,
    LeftTree,
  },
  created() {
    this.$forceNextTick(() => {
      bus.$emit('fitToWidthClassification')
    })
  },
  methods: {
    clearTreeDocSelection() {
      bus.$emit('classification/clearTreeDocSelection')
    },
  },
}
</script>

<style scoped lang="scss">
.card-body {
  padding: .5rem;
}
.pan-container {
  height: calc(100vh - 129px);
}
.node-tree-conent {
  overflow-y: auto;
}
</style>
