<template>
  <div
    v-if="classificationData.data && classificationData.data.length"
  >
    <div
      v-for="item in classificationData.data"
      :id="`view-classification-${ item.image_path }`"
      :key="item.image_path"
      class="d-flex align-items-center justify-content-bettween p-1 m-1 rounded cursor-pointer"
      :class="backgroundClass(item)"
      style="min-height: 99px; position: relative;"
      @click.exact="onClickPage(item)"
      @dblclick="showPopover(item.image_path)"
    >
      <div style="position: absolute; top: 7px; left: 7px;">
        <div class="file-index">
          {{ item.file_index + 1 }}
        </div>
      </div>
      <div
        :id="`view-classification-item-${ item.image_path }`"
        v-b-tooltip.hover
        :delay="{ show: 1000, hide: 50 }"
        :title="formattedRemarks(item.remarks)"
        class="flex-grow-1 pointer"
      >
        <h4
          v-if="!item.auto_classified_doc_type && !item.user_classified_doc_type"
          class="text-white text-center"
        >
          Unknown
        </h4>
        <h4
          v-if="item"
          class="text-white text-center"
        >
          {{ item.user_classified_doc_type || item.auto_classified_doc_type }}
        </h4>
        <p
          v-if="item.score"
          class="text-white text-center"
        >
          {{ `Score: ${item.score.toFixed(2) }` }}
        </p>
      </div>
      <b-popover
        v-if="activeBPopover === item.image_path"
        :show.sync="activeBPopover === item.image_path"
        :target="`view-classification-${ item.image_path }`"
        placement="auto"
        boundary="window"
        custom-class="no-padding-popover"
      >
        <ul
          class="m-n1"
          style="cursor: pointer; padding-inline-start: 0"
        >
          <li
            v-for="(type, idx) in classificationData.doc_types"
            :key="type + idx"
            class="border-bottom p-1"
            :class="item.auto_classified_doc_type === type? 'bg-primary': 'bg-white'"
            style="list-style-type: none;"
            @click.stop="closePopover(item.image_path, type)"
          >
            <span
              :class="item.auto_classified_doc_type === type? 'text-white': 'text-primary'"
            >
              {{ type }}
            </span>
          </li>
        </ul>
      </b-popover>
    </div>
  </div>
</template>
<script>
import bus from '@/bus'
import {
  BPopover, VBTooltip,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BPopover,
  },
  data() {
    return {
      activeBPopover: 'asdsad',
      loadingError: null,
      loading: null,
    }
  },
  computed: {
    classificationData() {
      return this.$store.getters['classifications/getClassificationData']
    },
  },
  created() {
    bus.$on('classification/clearTreeDocSelection', this.clearPopover)
  },
  mounted() {
    this.fetchManual()
    this.fetchRaJson()
  },
  destroyed() {
    bus.$on('classification/clearTreeDocSelection', this.clearPopover)
  },
  methods: {
    showPopover(imagePath) {
      if (this.classificationData.manual_classification_status === 'ready') {
        this.activeBPopover = imagePath
      }
    },
    clearPopover() {
      this.activeBPopover = ''
    },
    closePopover(imagePath, type) {
      this.activeBPopover = ''
      const classificationData = { ...this.classificationData }
      classificationData.data.forEach(item => {
        if (item.image_path === imagePath) {
          // eslint-disable-next-line no-param-reassign
          item.user_classified_doc_type = type
          // eslint-disable-next-line no-param-reassign
          item.color = 'green'
        }
      })
      this.$store.commit('classifications/SET_CLASSIFICATION_DATA', classificationData)
      this.$store.commit('classifications/SET_VERIFIED', false)
    },
    onClickPage(item) {
      const startIndex = item.layout_file_path.indexOf('TM')
      const pageId = item.layout_file_path.substring(startIndex, startIndex + 8)
      bus.$emit('scrollToPage', pageId)
      this.clearPopover()
    },
    backgroundClass(item) {
      const colorCodes = {
        green: 'bg-success',
        yellow: 'bg-warning',
        red: 'bg-danger',
      }

      return colorCodes[item.color]
    },
    async fetchRaJson() {
      const batchId = this.$route.params.id
      await this.$store.dispatch('classifications/fetchRaJsonClassification', batchId)
    },
    async fetchManual() {
      const batchId = this.$route.params.id
      await this.$store.dispatch('classifications/fetchManualClassification', batchId)
    },
    formattedRemarks(remarks) {
      if (!remarks || !remarks.length) {
        return 'No remarks'
      }
      return remarks.flat().join(', ')
    },
  },
}
</script>

<style scoped lang='scss'>
::v-deep .no-padding-popover .popover-body {
  padding: 0 !important;
}
.wide-popover {
  max-width: 100px; /* Set your desired width */
}
.file-index {
  // border: white solid 2px;
  border-radius: 40%;
  font-size: 1.2rem;
  width: 25px;
  height: 25px;
  opacity: .7;
  color: white;
  font-weight: bold;
  text-align: center;
  background: rgba(0, 0, 0, 0.8) ;
}
</style>
