<template>
  <div class="h-100">
    <CanvasViewClassification
      v-if="pages.length"
      class="canvas-view"
      :pages="pages"
    />
    <div
      v-else
      class="text-center h-100 d-flex align-items-center justify-content-center"
    >
      <b-spinner variant="primary" />
    </div>
  </div>
</template>

<script>
import Tiff from 'tiff.js'
import {
  BSpinner,
} from 'bootstrap-vue'
import { getClassificationMediaURL } from '@/store/batch/helper'
import CanvasViewClassification from '@//components/Classification/CanvasViewClassification.vue'

export default {
  components: {
    CanvasViewClassification,
    BSpinner,
  },
  data() {
    return {
      pages: [],
      tempPages: [],
    }
  },
  computed: {
    classificationData() {
      return this.$store.getters['classifications/getClassificationData']
    },
    classificationRaJson() {
      return this.$store.getters['classifications/getClassificationRaJosn']
    },
    getAlldocs() {
      return this.$store.getters['classifications/getAlldocs']
    },
  },
  watch: {
    classificationRaJson(value) {
      if (value) {
        this.loadPages()
      }
    },
  },
  methods: {
    async loadPages() {
      this.loading = true
      this.pages = []
      const pages = this.getAlldocs.map((page, index) => {
        const imagePath = getClassificationMediaURL(this.classificationData.data[index].image_path)
        let positionInfo
        if (page.pos) {
          positionInfo = page.pos.split(',').map(num => +num)
        }
        return {
          id: page.id,
          pos: page.pos,
          width: positionInfo[2],
          height: positionInfo[3],
          imageUrl: imagePath,
          image: null,
          wordNodes: page.children,
          styles: page.styles,
        }
      })
      this.tempPages[pages.length - 1] = undefined
      this.isDocidChanged = false
      const assignPages = async imageIndex => {
        try {
          const image = await this.fetchImage(pages[imageIndex].imageUrl)
          this.$emit('calculate-loaded-files', pages.length)
          pages[imageIndex].image = image

          if (this.pages.length === imageIndex) {
            this.pages.push(pages[imageIndex])
            let currentIndex = imageIndex + 1
            let nextPage
            if (currentIndex < pages.length) {
              nextPage = this.tempPages[currentIndex]
            }
            while (nextPage !== undefined) {
              this.pages.push(nextPage)
              currentIndex += 1
              if (currentIndex >= pages.length) {
                break
              }
              nextPage = this.tempPages[currentIndex]
            }
          } else {
            this.tempPages[imageIndex] = pages[imageIndex]
            if ((pages.length - 1) === imageIndex) {
              setTimeout(() => {
                for (let indexLocal = this.pages.length; indexLocal < this.tempPages.length; indexLocal += 1) {
                  if (this.tempPages[indexLocal] === undefined) {
                    break
                  }
                  this.pages.push(this.tempPages[indexLocal])
                }
              }, 1000)
            }
          }
        } catch (error) {
          this.loadingError = error.message
        }
        this.loading = false
      }

      let index = 0
      while (index < pages.length) {
        if (!this.isDocidChanged && !this.cancelLoading) {
          assignPages(index)
          index += 1
        } else {
          this.isDocidChanged = false
          this.loading = true
          this.$emit('calculate-loaded-files', 0)
          break
        }
      }
      this.$store.commit('batch/SET_TEMP_IMGE_LIST', this.pages)
    },
    async fetchImage(imageUrl) {
      try {
        const response = await fetch(imageUrl)
        const buffer = await response.arrayBuffer()
        const tiff = new Tiff({ buffer })
        return tiff.toCanvas()
      } catch (error) {
        throw new Error('Error loading image')
      }
    },
  },
}
</script>
