<template>
  <b-modal
    v-model="showModal"
    centered
    title="Submit Classifications"
    @hidden="$emit('modal-closed')"
    @ok="confirmHandler"
  >
    <b-card-text>
      <div>
        <span>Are you sure you want to submit modified classification data</span>?
      </div>
      <!-- <div v-else>
        Are you sure you want to delete the following batches?
        <div
          v-for="(id, index) of ids"
          :key="index"
          class="text-primary"
        >
          {{ id }}
        </div>
      </div> -->

    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isDeleting"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="isDeleting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
  },
  data() {
    return {
      showModal: true,
      isDeleting: false,
    }
  },
  methods: {
    async confirmHandler(event) {
      event.preventDefault()

      this.isDeleting = true

      const batchId = this.$route.params.id
      const isSucess = await this.$store.dispatch('classifications/verifyClassification', batchId)
      if (isSucess) {
        this.isDeleting = false
        this.$emit('submited')
        this.$router.push({ name: 'training' })
        this.showModal = false
      }

      // axios.delete('/batches/delete_multiple/', {
      //   data: {
      //     // remove_from_disk: this.removeFromDisk,
      //   },
      // })
      //   .then(() => {
      //     this.$emit('submited')

      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         // title: this.ids.length === 1 ? 'Batch deleted sucessfully' : 'Batches deleted sucessfully',
      //         icon: 'CheckIcon',
      //         variant: 'success',
      //       },
      //     })

      //     this.showModal = false
      //   })
      //   .catch(error => {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: error?.response?.data?.detail || 'Somthing went wrong',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'danger',
      //       },
      //     })

      //     this.isDeleting = false
      //   })
    },
  },
}

</script>
