var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollContainer",staticClass:"scroll-container",on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"large-container",style:({
      width: _vm.canvasWidth + 'px',
      height: _vm.canvasHeight + 'px',
      'margin-left': _vm.leftMargin + 'px'
    })},[(_vm.scrollNodeConfig.visible)?_c('div',{ref:"scrollNode",staticClass:"scroll-node",style:({
        top: _vm.scrollNodeConfig.y * _vm.zoom + 'px',
        left: _vm.scrollNodeConfig.x * _vm.zoom + 'px',
        width: _vm.scrollNodeConfig.width * _vm.zoom + 'px',
        height: _vm.scrollNodeConfig.height * _vm.zoom + 'px',
      })}):_vm._e(),_c('div',{staticClass:"h-100",style:({
        transform: ("translate(" + _vm.scrollX + "px, " + _vm.scrollY + "px)")
      })},[_c('v-stage',{attrs:{"config":_vm.stageConfig},on:{"mousedown":_vm.handleStageMouseDown,"touchstart":_vm.handleStageMouseDown,"mousemove":_vm.handleStageMouseMove,"touchmove":_vm.handleStageMouseMove,"mouseup":_vm.handleStageMouseUp,"touchend":_vm.handleStageMouseUp}},[_c('v-layer',{ref:"layer",attrs:{"config":_vm.imageLayerConfig}},[_vm._l((_vm.pageImages),function(imageConfig){return _c('v-image',{key:imageConfig.id,attrs:{"config":imageConfig}})}),_vm._l((_vm.wordNodesConfig),function(wordNodeConfig){return _c('v-rect',{key:wordNodeConfig.id,attrs:{"config":wordNodeConfig},on:{"mouseenter":_vm.nodeMouseEnterHandler,"mouseleave":_vm.nodeMouseLeaveHandler,"mousedown":_vm.nodeMouseDownHandler}})}),_c('v-rect',{attrs:{"config":_vm.selectionRectConfig}}),_c('v-rect',{attrs:{"config":_vm.scrollNodeConfig}}),_c('v-rect',{ref:"selectorRect",attrs:{"config":_vm.selectorRectConfig},on:{"transformend":_vm.handleTransformEnd,"dragend":_vm.handleDragEnd}}),_c('v-line',{attrs:{"config":_vm.measureLineConfig}}),_c('v-transformer',{ref:"transformer",attrs:{"config":_vm.transformerConfig}})],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }